const selectionTool = {
  key: 'ctech',
  label: 'Select',
  active: true,
  enabled: true,
  handlers: {
    click: (ev) => {
      // we hit nothing in the scene, let's clear the selction and notify listeners
      if (ev.hitNodes && ev.hitNodes.length === 0) {
        window.state.clearSelection(true);
        return;
      }

      if (!ev.hitNodes || !ev.hitNodes.length) return;

      const hit = ev.hitNodes[0]; // for now always just check first hit - do we care about clicking objects behind the first hit?
      const hitItem = hit.hierarchy.find(
        ({ nodeId }) => window.state.blocks[nodeId]
      );
      const hitCab = hit.hierarchy.find(
        ({ nodeId }) => window.state.cabinets[nodeId]
      );

      // the mouse clicked something, but it is not a selectable showroom item
      // (could be floor plane, etc)
      if (!hitItem) {
        // we hit a non-selectable item, let's clear the selection and notify listeners
        window.state.clearSelection(true);
        return;
      }
      window.state.selectItem(hitItem.nodeId, hitCab && hitCab.nodeId);
    },
  },
};

const moveTool = {
  active: true,
  enabled: true,
  key: 'dragging',
  handlers: {
    drag: (ev) => {
      if (!moveTool.enabled) return false;
      // window.state.clearSelection();

      if (!ev.hitNodes || !ev.hitNodes.length) return false;

      const hit = ev.hitNodes[0]; // for now always just check first hit - do we care about clicking objects behind the first hit?
      const hitItem = hit.hierarchy.find(
        ({ nodeId }) => window.state.blocks[nodeId]
      );
      const hitCab = hit.hierarchy.find(
        ({ nodeId }) => window.state.cabinets[nodeId]
      );

      // the mouse clicked something, but it is not a block
      if (!hitItem) return false;

      window.state.selectItem(hitItem.nodeId, hitCab && hitCab.nodeId);
      const { THREE } = window.api;
      const block = window.state.getBlock(hitItem.nodeId);
      const wall = window.state.getWall(block.location);
      const rayIntersection = new THREE.Vector3();
      const wallPlane = wall.getPlane();
      const intersect = ev.eventRay.ray.intersectPlane(
        wallPlane,
        rayIntersection
      );
      if (!intersect) return false;
      block.startedMoving();
      const axis = wall.getAxis();
      const initialAxisPosition = intersect[axis];
      const initialBlockPosition = block.getPosition();
      const element = ev.originalEvent.srcElement;
      return {
        handle(ev) {
          const currentElement = ev.originalEvent.srcElement;
          if (element != currentElement) return;
          const targetWorldPos = ev.eventRay.ray.intersectPlane(
            wallPlane,
            rayIntersection
          );
          if (targetWorldPos) {
            const deltaPos = targetWorldPos[axis] - initialAxisPosition;
            block.move(initialBlockPosition + deltaPos);
          }
        },
        onEnd: () => {
          block.finishedMoving();
        },
      };
    },
  },
};

export { moveTool, selectionTool };
