// Constants used by Components, always in inches
const RAIL_WIDTH = 1.5;
const RAIL_GROOVE = 0.125;
const CENTER_RAIL_OFFSET_DEPTH = 0.185;
const DRAWER_GAP = 0.011;
const DRAWER_SIDE_GAP = 0.2;
const FIRST_DRAWER_OFFSET = 0.15;
const SHELF_OFFSET_DEPTH = 1.725;
const SHELF_DEPTH_ADJUSTMENT = 2;
const CART_SHELF_DEPTH_ADJUSTMENT = 3.2;
const DOOR_HINGE_OFFSET = 0.115;
const DOOR_Z_OFFSET = -0.2;
const END_PANEL_SIT_BACK_OFFSET = 0.285;
const RADIUS_THICHNESS = 2;
const TOE_KICK_HEIGHT = 3.5;
const RADIUS_GASKET_LOCK_ADJUSTMENT = 3;
const CENTER_PANEL_DEPTH_ADJUSTMENT = 0.6;
const CART_CENTER_PANEL_DEPTH_ADJUSTMENT = 0.6;
const MAT_THICKNESS_INCHES = 0.09;
const CENTER_RAIL_ATTACH_PLATE_OFFSET = 0.05;
const SLOT_DEFAULT_SPACING = 8;
const LOCK_WIDTH = 3.2;

// Constants used by player, always in metres
const STRETCH_AMOUNT = 0.5;
const RAIL_MESH_LENGTH = 0.0998;
const END_RAIL_PLANE_HEIGHT = 0.03505;
const DRAWER_MSEH_WIDTH = 0.22;
const DRAWER_MESH_HEIGHT = 0.0925;
const DRAWER_MESH_DEPTH = 0.14;
const DRAWER_HEIGHT_DIFF = 0.5;
const DRAWER_RACK_SET_WIDTH = 0.18;
const DRAWER_RACK_SET_HEIGHT = 0.06;
const DRAWER_RACK_SET_DEPTH = 0.08;
const DOOR_SET_WIDTH = 0.142;
const DOOR_SET_HEIGHT = 0.05;
const DOOR_MESH_OFFSET = 6.785; // in CM
const SHELF_WIDTH = 0.08;
const SHELF_DEPTH = 0.1;
const FILLER_PANEL_MESH_SIZE = 0.05;
const FILLER_PANEL_MESH_DEPTH = 0.027;
const GAS_SPRING_X_ADJUSTMENT = 0.04112;
const GAS_SPRING_Y_ADJUSTMENT = 0.1991;
const GAS_SPRING_Z_ADJUSTMENT = 0.06;
const RADIUS_RAIL_MESH_WIDTH = 0.15;
const RADIUS_RAIL_MESH_DEPTH = 0.16;
const RADIUS_BASE_PANEL_MESH_WIDTH = 0.1;
const RADIUS_PANEL_MESH_WIDTH = 0.1495455231619136;
const RADIUS_PANEL_MESH_DEPTH = 0.16;
const MAT_THICKNESS = 0.002286;
const MAT_THICKNESS_CM = 0.2286;
const GASKET_LOC_MESH_WIDTH = 0.2;
const GASKET_LOC_MESH_HEIGHT = 0.0854;
const GASKET_LOC_MESH_DEPTH = 0.16;
const GASKET_LOC_WRAPPER_HIGHT = 2.6; // in cm
const GASKET_LOC_DEPTH_OFFSET = 0.4; // in cm
const CENTER_PANEL_MESH_HEIGHT = 0.21;
const CENTER_PANEL_MESH_DEPTH = 0.12;

export {
  STRETCH_AMOUNT,
  RAIL_MESH_LENGTH,
  RAIL_WIDTH,
  CENTER_RAIL_OFFSET_DEPTH,
  RAIL_GROOVE,
  DRAWER_GAP,
  DRAWER_SIDE_GAP,
  FIRST_DRAWER_OFFSET,
  SHELF_OFFSET_DEPTH,
  SHELF_DEPTH_ADJUSTMENT,
  CART_SHELF_DEPTH_ADJUSTMENT,
  DOOR_HINGE_OFFSET,
  DOOR_Z_OFFSET,
  END_PANEL_SIT_BACK_OFFSET,
  RADIUS_THICHNESS,
  TOE_KICK_HEIGHT,
  RADIUS_GASKET_LOCK_ADJUSTMENT,
  CENTER_PANEL_DEPTH_ADJUSTMENT,
  CART_CENTER_PANEL_DEPTH_ADJUSTMENT,
  MAT_THICKNESS_INCHES,
  CENTER_RAIL_ATTACH_PLATE_OFFSET,
  SLOT_DEFAULT_SPACING,
  //
  END_RAIL_PLANE_HEIGHT,
  DRAWER_MSEH_WIDTH,
  DRAWER_MESH_HEIGHT,
  DRAWER_MESH_DEPTH,
  DRAWER_HEIGHT_DIFF,
  DRAWER_RACK_SET_WIDTH,
  DRAWER_RACK_SET_HEIGHT,
  DRAWER_RACK_SET_DEPTH,
  DOOR_SET_WIDTH,
  DOOR_SET_HEIGHT,
  DOOR_MESH_OFFSET,
  SHELF_WIDTH,
  SHELF_DEPTH,
  FILLER_PANEL_MESH_SIZE,
  FILLER_PANEL_MESH_DEPTH,
  GAS_SPRING_X_ADJUSTMENT,
  GAS_SPRING_Y_ADJUSTMENT,
  GAS_SPRING_Z_ADJUSTMENT,
  RADIUS_RAIL_MESH_WIDTH,
  RADIUS_RAIL_MESH_DEPTH,
  RADIUS_BASE_PANEL_MESH_WIDTH,
  RADIUS_PANEL_MESH_WIDTH,
  RADIUS_PANEL_MESH_DEPTH,
  MAT_THICKNESS,
  MAT_THICKNESS_CM,
  GASKET_LOC_MESH_WIDTH,
  GASKET_LOC_MESH_HEIGHT,
  GASKET_LOC_MESH_DEPTH,
  GASKET_LOC_WRAPPER_HIGHT,
  GASKET_LOC_DEPTH_OFFSET,
  CENTER_PANEL_MESH_HEIGHT,
  CENTER_PANEL_MESH_DEPTH,
  LOCK_WIDTH,
};
